<template>
  <div class="statistics-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item><i class="icon-home"></i>图表分析</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <ul class="horizontal-nav">
        <li>
          <router-link to="/base/chart/basic">基础数据</router-link>
        </li>
        <li>
          <router-link to="/base/chart/sport">运动数据</router-link>
        </li>
        <li>
          <router-link to="/base/chart/monitor">监测数据</router-link>
        </li>
        <li>
          <router-link to="/base/chart/train">训练数据</router-link>
        </li>
        <li>
          <router-link to="/base/chart/plan">方案数据</router-link>
        </li>
        <li>
          <router-link to="/base/chart/service">服务数据</router-link>
        </li>
        <li>
          <router-link to="/base/chart/boost">促进数据</router-link>
        </li>
        <li>
          <router-link to="/base/chart/warning">预警数据</router-link>
        </li>
        <li>
          <router-link to="/base/chart/problem">问题数据</router-link>
        </li>
      </ul>
      <router-view></router-view>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="css" scoped>
.horizontal-nav {
  height: 44px;
  background: #32323a;
}
.horizontal-nav>li {
  float: left;
  height: 44px;
  line-height: 44px;
}
.horizontal-nav>li>a {
  display: block;
  padding: 0 20px;
  color: #999;
}
.horizontal-nav>li>a.active {
  color: #fff;
  background-color: #fdad00;
}
</style>
